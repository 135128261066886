/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table'

import { fecha_grilla, nagesConfig } from '../../funciones';
import { estado_general } from '../../schemas/sch_estados';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import BoxSistemas from './sistemas2';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts';
const nConf: nagesConfig = new nagesConfig()


function TablaSistemas() {
  return (
    <><div className="boxedfit" >
      <h3>Sistemas del conocidos</h3>
    </div>
      <div className="boxed" >
        <TablaSistemas3 />
      </div>
      <Stack direction="row">
        <Box className="boxed" >
          <ResumenSistemas />
        </Box>
        <Box className="boxed" >
          <ResumenNAGES />
        </Box>
      </Stack>
    </>)
}
export interface pieSistemas {
  id: number, value: number, label: string, color: string
}
export function ResumenNAGES() {
  const [datosNAGES, setdatosNAGES] = useState<pieSistemas[]>([]);

  useEffect(() => {
    let xpath = nConf.API_PATH + "/ages/sistema/obtener";
    let datos: pieSistemas[] = [{ id: 0, value: 0, label: "AGES", color: "#aa0000" }, { id: 1, value: 0, label: "NAGES", color: "yellow" }, { id: 2, value: 0, label: "NAGES2", color: "green" }]

    fetch(xpath)
      .then((res) => res.json())
      .then((data) => {
        data.map((sistema: any) => {
          datos[sistema.nages].value += 1
          return sistema
        })
        setdatosNAGES(datos)
      });
  }, []);

  return <>
    <Stack direction="column">
    <Box >
      <h4>Sistema de control</h4>
    </Box>
      <PieChart
        series={[
          {
            arcLabel: (item) => `${item.value}%`,
            arcLabelMinAngle: 25,
            data: datosNAGES,
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30 },            
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: 'black',
            fontWeight: 'bold',
            fontSize:14,
          },
        }}
        width={400}
        height={200}
      />
      </Stack></>;
}
function ResumenSistemas() {
  const [datas, setDatas] = useState<estado_general[]>([]);
  useEffect(() => {
    let xpath = nConf.API_PATH + "/ages/sistema/obtener";
    fetch(xpath)
      .then((res) => res.json())
      .then((data) => {
        data = distintos(data)
        setDatas(data)
      });
  }, []);

  const columns = useMemo<MRT_ColumnDef<estado_general>[]>(
    () => [
      {
        accessorKey: 'exe_ges_FECHA', //access nested data with dot notation
        header: 'GES',
        maxsize: 50,
        Cell: ({ cell }) => (
          <span>{fecha_grilla(cell.getValue<string>(), true)}</span>
        ),
      },
    ],
    [],
  );

  return <MaterialReactTable
    columns={columns}
    data={datas}
    enableGrouping
    initialState={{
      density: 'compact',
      sorting: [
        {
          id: 'ID_Sistema', //sort by age by default on page load
          desc: false,
        },
      ],
    }} />;
}
function distintos(data: any[]) {
  const uniqueDates = Array.from(new Set(data.map((item) => item.exe_ges_FECHA)));
  const filteredData = uniqueDates.map((date) => data.find((item) => item.exe_ges_FECHA === date));
  return filteredData;
}
function TablaSistemas3() {
  const [datas, setDatas] = useState<estado_general[]>([]);
  useEffect(() => {
    let xpath = nConf.API_PATH + "/ages/sistema/obtener";
    fetch(xpath)
      .then((res) => res.json())
      .then((data) => {
        setDatas(data)
      });
  }, []);

  const columns = useMemo<MRT_ColumnDef<estado_general>[]>(
    () => [
      {
        accessorKey: 'Fecha', //access nested data with dot notation
        header: 'Fecha',
        minSize: 70, //min size enforced during resizing
        maxSize: 100, //max size enforced during resizing
        size: 80, //medium column        
        Cell: ({ cell }) => (
          <span>{fecha_grilla(cell.getValue<string>(), true)}</span>
        ),
        enableGrouping: false
      },

      {
        accessorKey: 'ID_Sistema', //access nested data with dot notation
        header: 'SIS',
        enableGrouping: false,
        minSize: 30, //min size enforced during resizing
        maxSize: 60, //max size enforced during resizing
      },
      {
        accessorKey: 'Nombre',
        header: 'Nombre',
        maxsize: 50,
        enableGrouping: false,
      },
      {
        accessorKey: 'version_system',
        header: 'VER',
        minSize: 30, //min size enforced during resizing
        maxSize: 60, //max size enforced during resizing
        size: 30

      },
      {
        accessorKey: 'exe_ges_FECHA', //access nested data with dot notation
        header: 'GES',
        maxsize: 50,
        Cell: ({ cell }) => (
          <span>{fecha_grilla(cell.getValue<string>(), true)}</span>
        ),
      },
      {
        accessorKey: 'nages',
        header: 'NAGES',
        minSize: 30, //min size enforced during resizing
        maxSize: 60, //max size enforced during resizing
        size: 30

      },
    ],
    [],
  );

  return <>
    <MaterialReactTable
      columns={columns}
      data={datas}
      enableGrouping
      initialState={{
        density: 'compact',
        sorting: [
          {
            id: 'ID_Sistema', //sort by age by default on page load
            desc: false,
          },
        ],
      }} />
  </>;
}

export default TablaSistemas;

