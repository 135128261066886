export class nagesConfig {
  API_PATH: string
  constructor() {
    this.API_PATH = process.env?.REACT_APP_API_PATH || "Http://localhost:3001"
  } 
}
export class nagesConfig2 {
  API_PATH: string
  constructor() {
    this.API_PATH = process.env?.REACT_APP_API_PATH || "Http://localhost:3001"
  } 
}

export function fecha_grilla(fechaO: string, Hora: boolean) : string{
    if (fechaO === undefined) return ""
  
  let fecha = new Date(fechaO)
  let fecha_s=""
  fecha_s= fecha.getDate().toString().padStart(2,"0") + "/" + (fecha.getMonth()+1).toString().padStart(2,"0")  + "/" + fecha.getFullYear().toString().padStart(4,"0")
  if (Hora) {
    fecha_s+= " " + fecha.getHours().toString().padStart(2,"0")  + ":" + fecha.getMinutes().toString().padStart(2,"0") + ":" + fecha.getSeconds().toString().padStart(2,"0")
    } 
    return fecha_s
  }