import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, MRT_ColumnDef, MRT_Row, MRT_TableOptions, useMaterialReactTable } from 'material-react-table'
import { fecha_grilla, nagesConfig } from '../../../funciones';
import { indices } from '../../../schemas/sch_indices';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { actualizarIndices, borrarIndices } from './mod_indices';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
const queryClient = new QueryClient();
function TablaIndices() {
  return (
    <><div className="boxedfit" >
      <h3>Índices mensuales existentes</h3>
    </div>
      <div className="boxed" >
        <QueryClientProvider client={queryClient}>
          <TablaIndices2 />
        </QueryClientProvider>
      </div>
    </>)
}
function TablaIndices2() {
  const [datas, setDatas] = useState<indices[]>([]);
  const [reloads, setReload] = useState<Date>();
  useEffect(() => {
    const nConf: nagesConfig = new nagesConfig()
    let xpath = nConf.API_PATH + "/ages/tablas/indices/mensuales/";
    fetch(xpath)
      .then((res) => res.json())
      .then((data) => {
        let indices: indices[] = [];
        data.forEach((element: indices) => {
          console.log("fecha:", element.fecha)
          indices.push({
            fecha: element.fecha,
            indice1: element.indice1,
            indice2: element.indice2,
            indice3: element.indice3,
            indice4: element.indice4,
            indice5: element.indice5,
            coef: element.coef,
            a: element.a,
            a1: element.a1,
          });
        });
        
        setDatas(indices)
      });
  }, []);

  const columns = useMemo<MRT_ColumnDef<indices>[]>(
    () => [
      {
        accessorKey: 'fecha', //access nested data with dot notation
        header: 'Fecha',
        maxsize: 50,
        type: 'date',
        Cell: ({ cell }) => (
          <span>{fecha_grilla(cell.getValue<string>(), false)}</span>
        ),
      },
      {
        accessorKey: 'indice1',
        header: 'Coef',
        maxsize: 50,
      },
      {
        accessorKey: 'indice2', //normal accessorKey
        header: 'IPC',
        maxsize: 30,
      },
    ],
    [],
  );


  const handleCreateUser: MRT_TableOptions<indices>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    console.log("nuevo")
    await actualizarIndices(values)
    table.setCreatingRow(null);
    handlesetReload(values.codigo)
  };

  //UPDATE action
  const handleSaveUser: MRT_TableOptions<indices>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    console.log("grabar")
    await actualizarIndices(values)
    table.setEditingRow(null);
  };
  const { mutateAsync: deleteIndices, isPending: isDeletingIndices } = useDeleteIndices();


  //DELETE action
  const handlesetReload = (fecha: Date) => {
    console.log("hook par")
    setReload(fecha);
    return fecha
  }; 

  const openDeleteConfirmModal = (row: MRT_Row<indices>, handlesetReload: ((fecha: Date) => Date)) => {
    if (window.confirm('Está seguro de borrar este Indices?')) {

      deleteIndices(row.original.fecha).then(() => {
        console.log("Borro")
        handlesetReload(row.original.fecha) // Agrega un log que diga "hecho"
      })
      console.log("deleted " + row.original.fecha.toString())

    }
  };
  function useDeleteIndices() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (indiceFecha: Date) => {
        await borrarIndices(indiceFecha)
        await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
        return Promise.resolve();
      },
      //client side optimistic update
      onMutate: (indiceFecha: Date) => {
        queryClient.setQueryData(
          ['incoterm'],
          (prevUsers: any) =>
            prevUsers?.filter((indice: indices) => indice.fecha !== indiceFecha),
        );
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
    });
  }


  const table = useMaterialReactTable({
    columns, data: datas,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    onCreatingRowSave: handleCreateUser,
    onEditingRowSave: handleSaveUser,
    enableEditing: true,
    initialState: {
      density: 'compact',
      sorting: [
        {
          id: 'fecha',
          desc: true,
        },
      ],
      pagination: { pageSize: 20, pageIndex: 0 }
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [20, 50, 200],
      shape: 'rounded',
      variant: 'outlined',
      defaultValue: 20,
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Editar">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Borrar">
          <IconButton color="error">
            <DeleteIcon onClick={() => openDeleteConfirmModal(row, handlesetReload)}/>
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true);
        }}
      >
        Crear
      </Button>
    ),
  })
  return <MaterialReactTable table={table} />;
}

export default TablaIndices;

